.subsite {
    &__top {
        display: flex;
        max-height: 850px;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10vw;
        height: 33vw;
    }

    &__heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;

        h1 {
            font-size: 3.4vw;
            font-weight: bold;
            color: #252524;
            text-transform: uppercase;
            margin-right: 6vw;
            z-index: 2;
        }

        h2 {
            color: $color-extra;
            margin-right: 6vw;
            font-size: 1.7vw;
        }
    }

    .no-gutters {
        margin-bottom: 5vw;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &__top-img {
        position: relative;
        width: 60%;
        &:before {
            content: '';
            background-image: url('../../images/1.png');
            height: 100%;
            width: 25%;
            display: block;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            left: -10%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    h2 {
        color: $color-main;
    }

    p {
        font-weight: 400;
    }

    @include mq($until: xl) {
        &__heading {
            width: 45%;
    
            // h1 {
            //     font-size: rem(60px);
            // }
    
            // h2 {
            //     margin-top: 0;
            //     font-size: rem(25px);
            // }
        }

        &__top-img {
            width: 55%;
        }

        &__top {
            height: 31vw;
        }
    }

    @include mq($until: lg) {
        &__heading {
            // h1 {
            //     font-size: rem(55px);
            // }
    
            // h2 {
            //     font-size: rem(25px);
            // }
            h1 {
                font-size: 3.8vw;
            }
    
            h2 {
                font-size: 2vw;
                margin-top: 10px;
            }
        }

    }

    @include mq($until: md) {
        &__heading {
            h1 {
                font-size: 4.6vw;
                margin-bottom: 0;
            }
    
            h2 {
                font-size: 2.2vw;
            }
        }
    }

    @include mq($until: sm) {
        &__top-img {
            width: 40%;
            &:before {
                content: '';
                width: 100%;
                left: -30%;
            }
        }

        &__top {
            height: 300px;
        }

        &__heading {
            width: 60%;
        }
    }

    @include mq($until: xs) {
        &__top-img {
            width: 133px;
            height: 100%;
            right: 0;
            position: absolute;
            img {
                display: none;
            }

            &:before {
                width: 135px;
                left: -11%;
                position: relative;
            }
        }

        &__top {
            height: 300px;
        }

        &__heading {
            width: 100%;
            min-width: 240px;
        }

        &__heading {
            h1 {
                font-size: rem(45px);
                width: 70%;
            }
    
            h2 {
                font-size: rem(22px);
                width: 70%;
            }
        }
    }

    @include mq($until: 360) {
        &__top-img { 
            display: none;
        }
        &__heading {
            h1 {
                width: 90%;
            }
    
            h2 {
                width: 90%;
            }
        }
    }
}

.sub {
    background-color: white;
}

.page-subpage {

    .footer{
        &__container {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            position: relative;
        }

        &__logo {
            & + div {
                display: flex;

                & > div {
                    margin-right: 70px;
                }
            }
        }

        &__phones {
            .footer__icon {
                margin-top: 0;
            }
        }

        &__icon {
            padding-top: 7px;
            margin-right: 15px;
        }

        @include mq($until: lg) {
        
            &__logo {
                & + div {
                    justify-content: space-between;
                    & > div {
                        margin-right: 0;
                    }
                }
            }
        }

        @include mq($until: md) {
        
            &__logo {
                & + div {
                    flex-direction: column;
                    & > div {
                        
                    }
                }
            }
        }
    }
}