.top {
    &__img {
        display: grid;
        grid-template-columns: 40% 29% 29%;
        gap: 1%;
        img {
            height: 72%;
            width: 100%;
            object-fit: cover;
        }
    }

    @include mq($until: md) {
        img {
            height: 100%;
        }

        &__img {
            margin-top: 0;
            li {
            margin-top: 0;                
            }
            margin-bottom: 50px;            
        }
    }
    
    @include mq($until: sm) {

        &__img {
            height: 420px;
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 50%;
            grid-template-areas: 
              "a1 a1"
              "a2 a3"; 
        }

        // img {
        //     height: 50%;
        // }

        .a1 { grid-area: a1; }
        .a2 { grid-area: a2; }
        .a3 { grid-area: a3; }
    }

    @include mq($until: xs) {

        &__img {
            height: 320px;
        }

    }
}


.about {
    display: flex;
    margin-top: 10vw;

    &__img {
        width: 55%;
        height: 36vw;
        object-fit: cover;
        img {
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        width: 45%;
        padding-left: 5vw;
        display: flex;
        flex-direction: column;
        place-self: flex-end;

        &-p {
            width: 560px;
        }
    }

    a {
        font-weight: bold;
        margin-bottom: 2vw;
        margin-top: 5vw;
    }

    @include mq($until: xxl) {
        &__img {
            width: 50%;
            height: 40vw;
        }

        &__text {
            padding-left: 3.5vw;

            &-p {
                width: 500px;
            }
        }

        a {
            margin-bottom: 1.5vw;
            margin-top: 4vw;
        }
    }

    @include mq($until: xl) {
        &__img {
            height: 50vw;
        }      
        
        &__text {

            &-p {
                width: 440px;
            }
        }
    }

    @include mq($until: lg) {
        &__img {
            height: 55vw;
        }

        &__text {
            &-p {
                width: initial;
            }
        }
    }

    @include mq($until: md) {

        &__img {
            height: 40vw;
            width: 41%;
        }

        &__text {
        width: 50%;
        }
    }

    @include mq($until: xs) {
        flex-direction: column;

        &__img {
            height: 40vw;
            width: 100%;
            
            img {
                width: 90%;
            }
        }      
        
        &__text {
            margin-top: 50px;
            width: 100%;
            padding: 0 $padding-xs;
        }
    }
}

.gastro {
    margin-top: 10vw;
    display: flex;
    justify-content: space-between;
    color: white;

    h2 {
        color: white;
        font-size: 1.7vw;
        margin-left: 5vw;
        margin-bottom: 0.6vw;
    }

    ul {
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    p, h3 {
        color: white;
    }

    p {
        font-weight: 400;
        font-size: .85vw;
    }

    h3 {
        font-size: 1.3vw;
        display: flex;
        align-items: center;

        & + p {
            margin-left: 1.5vw;
        }
    }

    li a {
        padding: 1vw 3vw;
        margin: 0 2vw;
        display: block;
        transition: all .2s;

        &:hover {
            background-color: $color-main;
        }
    }

    &__offer {
        background-color: #d21113;
        margin: 4.667vw -1px;
        height: 28.888vw;
        padding: 4.1vw 0;

        & + a{
            margin-top: -1vw;
            display: block;
            font-size: 1vw;
            margin-left: 5vw;
            text-decoration: underline;
            color: black;
            text-decoration-thickness: 1px;
            transition: all .4s;
            &:hover {
                color: $color-main;
            }
        }
    }

    &__img {
        img {
            height: 38vw;
            width: 50vw;
            object-fit: cover;
        }
    }

    &__svg {
        margin-right: 1vw;
        svg {
            width: 3vw;
            height: 2.3vw;
        }
    }

    @include mq($until: xl) {

        h3 {
            font-size: 1.5vw;
            margin-bottom: .9vw;
        }

        p {
            font-size: 1vw;
        }

        &__offer {

            & + a{
                font-size: 1.5vw;
                margin-top: -1.8vw;
            }
        }
    }

    @include mq($until: lg) {

        &__offer {
            padding: 2.5vw 0;
        }

        h2 {
            font-size: 2vw;
            margin-left: 3vw;
        }

        h3 {
            font-size: 1.7vw;
            margin-bottom: .5vw;
        }

        li a {
            padding: 1vw 2vw;
            margin: 0 1vw;
        }

        p {
            font-size: 1.2vw;
        }
    }

    @include mq($until: md) {
        &__img {
            display: none;
        }

        &__svg {
            margin-right: 10px;
            svg {
                width: 5vw;
                height: 4.3vw;
            }
        }

        &__offer {
            height: auto;
            padding: 5vw 0;
        }

        h2 {
            font-size: rem($header-size / 1.3);
            margin-bottom: 15px;
            margin-left: 6vw;
        }

        h3 {
            font-size: rem($header-size / 1.5);
            margin-bottom: 10px;
        }

        p {
            font-size: rem(16px);
        }

        ul {
            grid-template-columns: 1fr;
            gap: 15px;
        }

        li a {
            padding: 2vw 4vw;
            margin: 0 2vw;
        }

        &__offer {

            & + a{
                font-size: rem($header-size / 2);
            }
        }
    }
}

.banner {
    margin: 10vw 0;

    &__buttons-set {
        width: 100%;
        position: relative;
    }

    img {
        width: 100%; 
    }

    &__button {
        position: absolute;
        font-size: 2vw;
        font-weight: 700;
        bottom: 0;
        padding: 2.33vw 1vw;
        text-align: center;

        &_left {
            background-color: rgba(0, 0, 0, 0.8);
            color: white;
            width: 40.1vw;
        }

        &_right {
            right: 0;
            background-color: rgba(255, 255, 255, 0.8);
            color: $color-main;
            width: 25vw;
        }
    }
}