.icons-set {
  display: grid;
  gap: 1vw;
  padding: 0 5vw;

  &__item {
    flex-direction: column;
    padding: 0;
    justify-self: center;
    cursor: pointer;

    img {
      display: block;
      height: 100px;
      // filter: opacity(0.8) drop-shadow(0 0 0 $color-main) contrast(250%) contrast(190%) brightness(110%);
      margin-bottom: 40px;
    }

    &:hover {

      .icons-set__item-description {
        opacity: 1;
      }

      .icons-set__item-text {
        opacity: 0;
      }

    }

    &::before {
      content: none;
    }

  }

  &__item-description {
    opacity: 0;
    transition: all 0.3s;
    font-size: rem(14px);
    margin-right: 13px;
    margin-top: -55px;
    font-weight: 400;
  }
  
  &__item-text {
      font-size: rem(18px);
      font-weight: 700;
      transition: all .2s;
  }

  @include mq($until: xxl) {
    padding: 0 2vw;
      &__item-text {
        font-size: rem(15px);
    }
    
    &__item img {
      height: 75px;
    }
  }

  @include mq($until: xl) {
    padding: 0;
      &__item-text {
        font-size: rem(14px);
    }

    &__item img {
      height: 65px;
      margin-bottom: 30px;
    }
  }

  @include mq($until: lg) {
    padding: 0 5vw 0 20vw;

    &__item {
      margin-bottom: 30px;
      justify-self: self-start;
    }

  }

  @include mq($until: md) {
    padding: 0 0 0 14vw;
    &__item {
    }
  }

  @include mq($until: sm) {
    padding: 0;
    &__item-text {
      font-size: rem(14px);
    }
    &__item {
      img {
        height: 45px;
      }
      justify-self: center;
      min-width: 125px;
    }
  }

  @include mq($until: xs) {
   &__item {
      img {
        height: 30px;
        margin-bottom: 10px;
      }
      justify-self: center;
      min-width: 120px;
   }
  }
}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: lg) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @include mq($until: xs) {
    &--6 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
