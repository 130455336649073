.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 150px;

  &--fixed { 
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    max-width: 285px;
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0 20px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-font-dark;
    font-size: rem(25px);
    font-weight: 600;
    text-transform: uppercase;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }

  @include mq($until: xxl) {
    min-height: 140px;
    
    &__menuLink {
      font-size: rem(20px);
    }

    &__logo {
      max-width: 200px;
    }
  }

  @include mq($until: xl) {
    min-height: 130px;

    &__menuLink {
      font-size: rem(19px);
    }

    &__logo {
      max-width: 180px;
    }

    &__menuItem {
      margin: 0 15px;
    }
  }

  @include mq($until: lg) {
    min-height: 110px;

    &__menuLink {
      font-size: rem(17px);
    }

    &__logo {
      max-width: 180px;
    }
  }

  @include mq($until: md) {
    min-height: 90px;

    &__logo {
      max-width: 130px;
    }

    &__menuLink {
      color: white;
      &:active, &--active, &--open, &:hover {
        color: black;
      }
    }

    &__menuList {
      align-items: end;
    }

    &__menuItem {
      margin: 0 15px;
    }
  }
}

div#flags {

  a {
    margin: 0 10px;
  }

  img {
      width: 40px;
      opacity: 0.5;
      transition: all .3s;
      &:hover {
          opacity: 1;
      }
  }

  .active-lang  {
      img {
          opacity: 1;
          box-shadow: 0 0 8px #00000050;
      }
  }

  @include mq($until: md) {
    position: absolute;
    right: 13%;

    a {
      margin: 0 7px;
    }

    img {
      width: 30px;
    }
  }
}