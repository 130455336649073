footer {
  margin-top: 5vw;
}

.footer {

  a.leaflet-control-zoom-in,
  a.leaflet-control-zoom-out {
    color: black;
  }

  &__logo {
    margin-bottom: 60px;
    max-width: 250px;
  }

  background-color: #252524;
  padding: 85px 0;
  &__phones {
    display: flex;
    margin-bottom: 40px;
    &-list {
      display: flex;
      flex-direction: column;

      li {
        margin-bottom: 20px;
      }
    }

    .footer__icon {
      margin-top: -13px;
    }
  }

  &__email {
    display: flex;
  }

  &__icon {
    margin-right: 30px;
    width: 40px;
  }

  &__adr {
    margin-bottom: 60px;
  }

  a, p {
    color: white;
    font-weight: bold;
    margin-bottom: 0;
    transition: all .2s;
  }

  a {
    &:hover {
      color: $color-main;
    }
  }

  &__map {
    height: 600px;
    width: 60vw;
    margin-top: -255px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__menu {
    position: absolute;
    right: 0;
  }

  &__menuList {
    display: flex;
    margin-top: 0;
  }

  &__menuLink {

    text-transform: uppercase;
    margin-left: 3vw;
    font-weight: 600 !important;
    font-size: rem(17px);

    &--active {
      color: $color-main !important;
    }
  }

  @include mq($until: xxl) {
    &__logo {
      max-width: 200px;
    }

    &__map {
      width: 55vw;
    }
  }

  @include mq($until: xl) {
    &__logo {
      max-width: 180px;
    }

    &__map {
      width: 50vw;
    }
  }

  @include mq($until: md) {
    &__logo {
      max-width: 130px;
    }
    &__menuLink {
      margin-left: 5vw;
    }  
  }

  @include mq($until: sm) {

    &__container {
      flex-direction: column-reverse;
    } 


    &__adr {
      margin-bottom: 40px;
    }

    &__map {
      height: 300px;
      width: 100%;
      margin-top: -120px;
      margin-bottom: 20px;
    }

    &__phones {
      margin-bottom: 30px;
    }
  }

  @include mq($until: xs) {
    &__menuList {
      margin-top: 65px;
      justify-content: space-between;
    }

    &__menuLink {
      font-size: 3.3vw;
      margin-left: 0;
    }

    &__logo {
      margin-bottom: 100px;
    }

    &__menu {
      width: 100%;
    }
  }
}

.footer-small{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;

  &__realization{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
      transition: color .3s ease-in-out;
    }
    &:hover span{
      color: $color-main;
    }
    img{
      margin-left: 15px;
    }
  }

  a, div, span {
    font-weight: bold;
    color: #A2A2A2;
    font-size: rem(14px);
  }
}

.cookies-on {
  margin-bottom: 60px;
}